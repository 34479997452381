
.gantt-impacts .calendar rect:first-child {
    background: linear-gradient(0deg, #F4F6F8, #F4F6F8) , linear-gradient(0deg, #e0e6eb, #e0e6eb);
    fill: #F4F6F8;
    color: #000000;
}
.gantt-impacts .calendar text {
    color: #212B36 !important;
    fill: #212B36 !important;
}

.userback-comment {
    z-index: 99999999999999999999999 !important;
}

.notistack-SnackbarContainer {
    background-color: transparent;
    color: black;
    z-index: 99999999999999 !important;
}

#notistack-snackbar {
    color: black;
}


.notistack-MuiContent-success {
    color: #43a047 !important;
    background-color: whitesmoke !important;
}
.notistack-MuiContent-error {
    color: #d32f2f;
    background-color: whitesmoke !important;
}
.notistack-MuiContent-warning {
    color: #ff9800;
    background-color: whitesmoke !important;
}

.notistack-MuiContent-info {
    color: #2196f3;
    background-color: whitesmoke !important;
}

/* Chrome, Safari, Edge, Opera */
input.incrimenter::-webkit-outer-spin-button,
input.incrimenter::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input.incrimenter[type=number] {
    -moz-appearance: textfield;
}


@media print {
    .export-button {
        visibility: hidden;
    }

    #filter-block {
        display: none;
    }
}


.truncate {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.word-wrap {
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.truncate-extend {
    width: 90%;
}

@media (max-width:1400px) {
    .truncate-chip {
        width: 90%;
    }
}

.bb-button,
button[class*="pendo"] {
    cursor: pointer;
}

button#_pendo-badge_iTF_yJWYZGnc315WOwegBbKV858,
button#_pendo-badge_dICVrGFpPzzNHCr-9BtyvgCzhdM,
button#_pendo-badge_OQXWwP45PB5GB1S79vFUZPMK7zc,
button#_pendo-badge_68Dz9-wB8_1d9HkVdce8Lh5l_OY,
button#_pendo-badge_sEN7tQIiQlXCkbGZyMUoDBPQQ4k {
    z-index: 900!important;
}


button#_pendo-badge_dICVrGFpPzzNHCr-9BtyvgCzhdM {
   left: 50.2% !important;
}

@media (max-width:1400px) {
    button#_pendo-badge_dICVrGFpPzzNHCr-9BtyvgCzhdM {
        left: 54.2% !important;
    }
}

.bounce-in {
    animation: bounce-in 2s ease infinite;
}
@keyframes bounce-in {
    0% {
        opacity: 0;
        transform: scale(.3);
    }
    50% {
        opacity: 1;
        transform: scale(1.05);
    }
    70% { transform: scale(.9); }
    100% { transform: scale(1); }
}