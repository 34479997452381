.svg-heatmap-container {
    width: 100%;
}


.initiative,
.impact {
    color: rgb(0, 0, 0);
    font-size: 13.3333px;
    height: 20px;
    background-color: #f6f5e7;
    margin-top: 3px;
}

.panel-header {
    font-size: 15px;
    font-weight: bold;
}

.option-list:hover a div text {
    color: gray;
}

.option-list:hover a div span {
    display: none;
    border: 1.5px solid #767674;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    border: 1.5px solid black;
    cursor: pointer;
}

.dropdown-menu li:focus {
    outline: none;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 2px;
    top: -7px;
    width: 7px;
    height: 20px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg) scale(0.5);
}

input::-webkit-calendar-picker-indicator {
    display: none;
}

input[type="date"]::-webkit-input-placeholder {
    visibility: hidden !important;
}

.option-list {
    height: 20px;
}


.blur {
    box-shadow: 0px 0px 20px 20px rgba(255, 255, 255, 1);
    /* text-shadow: 0px 0px 10px rgba(51, 51, 51, 0.9); */
    transform: scale(0.9);
    opacity: 0.6;
}

.bts-barcode-prompt {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 12;
    color: #fff;
    background: rgba(0, 0, 0, 0.9);
    font-size: 10px;
    border-radius: 2px;
    padding: 4px;
    -webkit-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
}

#center-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    width: 50%;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    padding: 10px;
    color: #424242;
}

#dismiss-button {
    float: right;
    padding: 2px 5px;
    background: #ccc;
    cursor: pointer;
}

#edit-scenario-label {
    font-size: 12px;
}

#scenario-save-button {
    margin-top: 15px !important;
}

@media only screen and (max-width: 768px) {
    #edit-scenario-label {
        font-size: 11px;
    }

    #scenario-save-button {
        padding: 2px !important;
        font-size: 10px !important;
        margin-top: 20px !important;
    }
}

/*.row-separator-hr {
    margin-top: 40px;
    margin-bottom: 40px;
}*/

.dimmed {
    position: relative;
}

.dimmed:after {
    content: " ";
    z-index: 10;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
}


#heatmaps-container {
    /*
    overflow: hidden !important;
     */
}